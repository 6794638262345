export default {
    data: () => ({
        main: 'ORDER',
        items: [],
        selected: 0
    }),
    methods: {
        add() {
            this.items.push(Math.random(100))
        },
        check( event ) {
            if( this.selected == this.items.length ) {
                event.preventDefault()
                this.add()
            }
        },
        getFocus( index ) {
            this.selected = index + 1
        }
    }
}